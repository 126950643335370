












import { Component, Vue } from 'vue-property-decorator'
import SideBar from '@/components/SideBar.vue'
import { VueGevekom } from './components/applications/Form.interfaces'

@Component({
  components: { SideBar }
})
export default class App extends Vue {
  private root!: VueGevekom
  private showProgressBar = true
  private receivedSelection = 'applications'

  configs: any

  created() {
    this.root = this.$root as VueGevekom

    if (
      !this.root.rightsService.isInited &&
      this.$store?.state?.auth?.tenantId &&
      this.$store.state?.auth?.tokenDictionary?.QM?.tokens?.qmAPIToken
    ) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.rightsService.initService(tenantId, token)
      console.log('[App] has user the right settings?:', this.root.rightsService.hasAuthedUserThisRight('settings'))
    }
  }
}
