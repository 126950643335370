/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRx from 'vue-rx'

import InputFacade from 'vue-input-facade'
import VueMask from 'v-mask'

import JsonCSV from 'vue-json-csv'

import CKEditor from '@ckeditor/ckeditor5-vue2'
import Clipboard from 'v-clipboard'

/**
 * app services
 */
import UserSettingsSingletonService from '@/appServices/userSettings.singleton.service'
import RightsSingletonService from '@/appServices/rights.singleton.service'
import ApplicationsSingletonService from './appServices/applications.singleton.service'
import CampaignsSingletonService from './appServices/campaigns.singleton.service'

Vue.config.productionTip = false

Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueAxios, axios)
Vue.use(InputFacade)
Vue.use(VueMask)
Vue.use(VueRx)

Vue.use(CKEditor)
Vue.use(Clipboard)

Vue.component('downloadCsv', JsonCSV)

new Vue({
  router,
  store,
  vuetify,
  data: {
    applicationService: new ApplicationsSingletonService(),
    userSettingsService: new UserSettingsSingletonService(),
    rightsService: new RightsSingletonService(),
    campaignsService: new CampaignsSingletonService()
  },
  render: h => h(App)
}).$mount('#app')
