





































import { Tile } from '@/appServices/rights.singleton.service'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { VueGevekom } from './applications/Form.interfaces'

@Component
export default class SideBar extends Vue {
  private root!: VueGevekom
  private cards: Tile[] = []

  @Watch('$store.state.auth.tokenDictionary')
  watchStoreAuth(newValue: any, oldValue: any) {
    console.log('[SideBar] Watched watchStoreAuth NEW:', newValue)
    console.log('[SideBar] Watched watchStoreAuth OLD:', oldValue)
    if (
      !this.root.rightsService.isInited &&
      this.$store?.state?.auth?.tenantId &&
      this.$store.state?.auth?.tokenDictionary?.QM?.tokens?.qmAPIToken
    ) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.rightsService.initService(tenantId, token)
    }

    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.applicationService.initService(tenantId, token)
    }

    this.root.applicationService.getDashboardTiles$().subscribe((tiles: Tile[]) => {
      this.root.rightsService.dashboardTiles.next(tiles)
    })
  }

  created() {
    console.log('[SideBar] Created the SideBar class')
    this.root = this.$root as VueGevekom

    this.root.rightsService.dashboardTiles.subscribe((tiles) => {
      this.cards = []
      for (const tile of tiles) {
        this.cards.push(tile)
        // if (this.root.rightsService.hasAuthedUserThisRight(tile.right || '')) {
        //   this.cards.push(tile)
        // }
      }
    })
  }

  selectedLink(target: any): void {
    console.log('[SideBar] Emitting a link from sidebar:', target)
    this.$emit('link-select', target)
  }
}
