import { BehaviorSubject, defer, Observable } from 'rxjs'
import http from '../http-common'

import store from '@/store'
import { map } from 'rxjs/operators'
import { AxiosResponse } from 'axios'

export interface Tile {
  title: string
  src: string
  flex: number
  icon: string
  right?: string
}

export interface AppRight {
  id: string
  rightName: string
  rightValue: string
  createdDate: string
  memberLoginNames?: string[]
}

class RightsSingletonService {
  private _isInited = false
  private headersBasic: Record<string, any> = {}

  public tenantId!: string
  public token!: string

  public dashboardTiles: BehaviorSubject<Tile[]> = new BehaviorSubject<Tile[]>([])

  get isInited(): boolean {
    return this._isInited
  }

  initService(tenantId: string, token: string, overwrite = false) {
    if (!this._isInited || overwrite) {
      this.tenantId = tenantId
      this.token = token
      this.headersBasic = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
      this._isInited = true
      console.log('[RightsSingletonService] has been inited!')
      console.log('[RightsSingletonService] Found store:', store.state)
    }
  }

  hasAuthedUserThisRight(rightValue: string): boolean {
    if (store.state?.auth?.user?.rights) {
      for (const right of store.state.auth.user.rights) {
        // console.log('[RightsSingletonService] check for right and rightValue:', right, rightValue)
        if (right.rightValue === rightValue) {
          return true
        }
      }
    }
    return false
  }

  addLoginNameToRights$(rightId: string, loginName: string): Observable<any> {
    return defer(() =>
      http.post(`/api/rights/v1/tenants/${this.tenantId}/${rightId}/addLoginName/${loginName}`, {}, this.headersBasic)
    ).pipe(map((response: AxiosResponse<any>) => response.data.data)) as Observable<any>
  }

  removeLoginNameFromRights$(rightId: string, loginName: string): Observable<any> {
    return defer(() =>
      http.post(`/api/rights/v1/tenants/${this.tenantId}/${rightId}/removeLoginName/${loginName}`, {}, this.headersBasic)
    ).pipe(map((response: AxiosResponse<any>) => response.data.data)) as Observable<any>
  }

  getRightsIndex$(withUsers = false, withLoginNames = false) {
    const config = { ...this.headersBasic }
    const params: { withUsers?: boolean; withLoginNames?: boolean } = {}
    if (withUsers) {
      params.withUsers = true
    }
    if (withLoginNames) {
      params.withLoginNames = true
    }

    return defer(() => http.get(`/api/rights/v1/tenants/${this.tenantId}/`, { ...config, params })).pipe(
      map((response: any) => {
        const result: { rights: any[]; loginNames: string[] } = { rights: [], loginNames: [] }
        if (response?.data?.index) {
          result.rights = response.data.index
        }
        if (response?.data?.loginNames) {
          result.loginNames = response.data.loginNames
        }
        return result
      })
    )
  }

  getMembersForRight$(rightId: string) {
    const config = { ...this.headersBasic }

    return defer(() => http.get(`/api/rights/v1/tenants/${this.tenantId}/${rightId}/members`, { ...config })).pipe(
      map((response: any) => {
        let members: { userId: string; loginName: string }[] = []
        if (response?.data?.members) {
          members = response.data.members
        }
        return members
      })
    )
  }
}

export default RightsSingletonService
