






import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Logout extends Vue {
  created() {
    // Lifecycle Event when component created and ready for use
    console.log('[Logout] lifecycle created event:', this)
    this.axios
      .get(`/api/auth/v1/tenants/${this.$store.state.auth.tokenDictionary.QM.tenantId}/logout`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken}`,
          'content-type': 'application/json;charset=utf-8'
        }
      })
      .then((response) => {
        console.log('[Logout] Response from Logout:', response)
        // Reset the store and destroy all infos about login and/or app data
        this.$store.dispatch('resetAppState')
        sessionStorage.clear()
        // this.$router.push('/applications')
        this.$router.push('/dashboard')
      })
      .catch((err) => {
        console.error('[Logout] Error from logout:', err)
        // Reset the store and destroy all infos about login and/or app data
        this.$store.dispatch('resetAppState')
        sessionStorage.clear()
        // this.$router.push('/applications')
        this.$router.push('/dashboard')
      })
  }
}
