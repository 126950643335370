





























































import { AppRight } from '@/appServices/rights.singleton.service'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { VueGevekom } from '../applications/Form.interfaces'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import _ from 'lodash'

@Component({ components: { ConfirmDialog } })
export default class RightsSettings extends Vue {
  private root!: VueGevekom

  private appRights: AppRight[] = []
  private loginNames: string[] = []
  private loginName = ''

  private appRight: string | null | undefined = null
  private selectedRight: AppRight | null | undefined = null

  private showConfirmDialog = false
  private deleteLoginName: any

  get membersLoginNames() {
    return this.selectedRight?.memberLoginNames?.sort()
  }

  @Watch('loginName')
  loginNameChanged(newValue: string) {
    console.log('[RightsSettings] loginName changed to:', newValue)
    // this.processAppRightChange(newValue)
  }

  @Watch('appRight')
  appRightChanged(newValue: string) {
    console.log('[RightsSettings] appRightDropDown changed to:', newValue)
    this.processAppRightChange(newValue)
  }

  created() {
    console.log('[RightsSettings] Created, now initing component.')
    this.root = this.$root as VueGevekom
    this.$store.commit('showProgressBar')

    this.root.rightsService.getRightsIndex$(true, true).subscribe((response: { rights: AppRight[]; loginNames: string[] }) => {
      console.log('[RightsSettings] Received rights index:', response)
      this.appRights = response.rights
      this.appRights.sort()
      this.selectedRight = this.appRights[0]
      if (this.selectedRight?.memberLoginNames) {
        this.selectedRight.memberLoginNames.sort()
      }
      this.appRight = this.selectedRight.rightValue
      this.loginNames = response.loginNames.sort()
      this.$store.commit('hideProgressBar')
    })
  }

  processAppRightChange(newValue: string) {
    const selectedRight: AppRight | undefined = _.find(this.appRights, { rightValue: newValue })
    console.log('[RightsSettings] Found right in list for processing:', selectedRight)
    this.selectedRight = selectedRight
  }

  addLoginNameToRight() {
    ;(this.$refs.selectLoginNames as HTMLElement).focus()
    this.$store.commit('showProgressBar')
    console.log('[RightsSettings] Add Login to right:', this.loginName, this.selectedRight)
    if (this.loginName?.length > 0 && this.selectedRight) {
      this.root.rightsService.addLoginNameToRights$(this.selectedRight.id, this.loginName).subscribe((response: any) => {
        console.log('[RightsSettings] Added Login to right and got response:', response)
        this.selectedRight = response
        if (this.selectedRight?.memberLoginNames) {
          this.selectedRight.memberLoginNames.sort()
        }
        const idx = _.findIndex(this.appRights, { id: response.id })
        this.appRights.splice(idx, 1, this.selectedRight as AppRight)
        this.appRights.sort()
        this.$store.commit('hideProgressBar')
      })
    } else {
      this.$store.commit('hideProgressBar')
    }
  }

  deleteLoginNameFromRight(member: any) {
    this.deleteLoginName = member
    this.showConfirmDialog = true
  }

  deleteLoginNameFromRightConfirmed() {
    this.showConfirmDialog = false
    this.$store.commit('showProgressBar')
    console.log('[RightsSettings] Remove Login from right:', this.deleteLoginName, this.selectedRight)
    if (this.deleteLoginName?.length > 0 && this.selectedRight) {
      this.root.rightsService.removeLoginNameFromRights$(this.selectedRight.id, this.deleteLoginName).subscribe((response: any) => {
        console.log('[RightsSettings] Removed Login from right and got response:', response)
        this.selectedRight = response
        if (this.selectedRight?.memberLoginNames) {
          this.selectedRight.memberLoginNames.sort()
        }
        const idx = _.findIndex(this.appRights, { id: response.id })
        this.appRights.splice(idx, 1, this.selectedRight as AppRight)
        this.appRights.sort()
        this.$store.commit('hideProgressBar')
      })
    } else {
      this.$store.commit('hideProgressBar')
    }
  }

  private getAppRightName(appRight: string) {
    const r = _.find(this.appRights, { rightValue: appRight })
    return r ? r.rightName : '-'
  }
}
