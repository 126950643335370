



























import { Vue, Component } from 'vue-property-decorator'

@Component({ components: {} })
export default class GeneralSettings extends Vue {
  private loading = false
  private panel: any = null
  private settingOpen = false

  handleShowLoading(evt: boolean) {
    this.loading = evt || false
  }

  handlePanelChange() {
    console.log(`[GeneralSettings] handle panel change:`, this.panel)
  }
}
