




















import { Vue, Component } from 'vue-property-decorator'
import GeneralSettings from './General.vue'
import DropdownContentsSettings from './DropdownsContent.vue'
import RightsSettings from './Rights.vue'
import { VueGevekom } from '../applications/Form.interfaces'

@Component({ components: { GeneralSettings, DropdownContentsSettings, RightsSettings } })
export default class SettingsMain extends Vue {
  private root!: VueGevekom

  created() {
    this.root = this.$root as VueGevekom
  }
}
