import { StatusType } from '@/appInterfaces/applications.interfaces'
import { ConfigKeys } from '@/components/settings/settings.interfaces'
import { BehaviorSubject, defer, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
// import moment from 'moment'
import http from '../http-common'

import store from '@/store'
import { Tile } from './rights.singleton.service'

export interface ApplicationComment {
  id?: string
  commentedAt?: string
  commenterName: string
  commenterLogin: string
  commenterId: string
  comment: string
  rating: number
}

export interface ApplicationGetIndexOptions {
  day?: string
  yearMonth?: string
  year?: string
  quartal?: string
}

class ApplicationsSingletonService {
  private _isInited = false
  private _tenantId!: string
  private _token!: string
  private headersBasic: Record<string, any> = {}

  private statusConfig: BehaviorSubject<StatusType[]> = new BehaviorSubject<StatusType[]>([])

  public testSubject: BehaviorSubject<string> = new BehaviorSubject('')

  get isInited(): boolean {
    return this._isInited
  }

  get statusConfig$(): Observable<StatusType[]> {
    return this.statusConfig.asObservable()
  }

  get token(): string {
    return this._token
  }

  get tenantId(): string {
    return this._tenantId
  }

  initService(tenantId: string, token: string, overwrite = false) {
    console.log('[ApplicationsSingletonService] store state:', store.state)
    if (!this._isInited || overwrite) {
      this._tenantId = tenantId
      this._token = token
      this.headersBasic = {
        headers: {
          Authorization: `Bearer ${this._token}`
        }
      }
      this._isInited = true
    }
  }

  getDataConfigs$(): Observable<any> {
    return defer(() => http.get(`/api/auth/v1/tenants/${this._tenantId}/dataConfigs`, this.headersBasic)).pipe(
      map((cfgs: any) => {
        console.log('[ApplicationsSingletonService] received map of data config:', cfgs)
        if (cfgs.data?.configs?.status) {
          this.statusConfig.next(cfgs.data.configs.status)
        } else {
          this.statusConfig.next([])
        }
        return cfgs
      })
    )
  }

  updateDataConfig$(configKey: string, newConfig: ConfigKeys): Observable<any> {
    const payload = {
      config: configKey,
      newConfig
    }
    return defer(() => http.post(`/api/auth/v1/tenants/${this._tenantId}/dataConfigs`, payload, this.headersBasic))
  }

  isAdmin(): boolean {
    return store.state.auth.viewRights?.admin || false
  }

  isAdminFK(): boolean {
    return store.state.auth.viewRights?.adminFK || false
  }

  isAdminAgents(): boolean {
    return store.state.auth.viewRights?.adminAgents || false
  }

  getDashboardTiles$() {
    return of<Tile[]>([
      { title: 'QM Abfragen Admin', src: '/qm-selections', flex: 3, icon: 'mdi-database-search', right: 'tests' },
      { title: 'Test', src: '/test', flex: 3, icon: 'mdi-airballoon', right: 'tests' },
      { title: 'Einstellungen', src: '/settings', flex: 3, icon: 'mdi-account-multiple', right: 'settings' }
    ])
  }
}

export default ApplicationsSingletonService
