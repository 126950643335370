















































































































































































import { VueGevekom } from '@/components/applications/Form.interfaces'
import { Vue, Component, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { Campaign } from '@/appInterfaces/campaigns.interfaces'

@Component
export default class Test extends Vue {
  private root!: VueGevekom
  private title = 'Test works'
  private campaigns: Campaign[] = []
  private snackbar = false
  private snackbarText = ''

  private choosenGroups: string[] = []
  private choosenColumns: string[] = []
  private choosenFilters: string[] = []

  private forceReloadFromDF = false
  private selectedCampaign = null
  private selectedCampaignObject: Campaign | null | undefined = null
  private loadedCampaignObject: any = null
  @Watch('selectedCampaign')
  selectedCampaignChanged(newValue: string) {
    this.selectedCampaignObject = _.find(this.campaigns, { id: newValue })
    console.log('[Test] value of selectedCampaign has changed to:', this.selectedCampaignObject)
    if (this.selectedCampaignObject) {
      this.$store.commit('showProgressBar')
      this.root.campaignsService.getCampaign$(this.selectedCampaignObject.id, this.forceReloadFromDF).subscribe((response: Campaign) => {
        console.log('[Test] received campaign detail data from AS5:', response)
        if (response) {
          this.loadedCampaignObject = response
        }
        this.$store.commit('hideProgressBar')
      })
    }
  }

  created() {
    console.log('[Test] Created the Test class')

    this.root = this.$root as VueGevekom

    if (
      !this.root.rightsService.isInited &&
      this.$store?.state?.auth?.tenantId &&
      this.$store.state?.auth?.tokenDictionary?.QM?.tokens?.qmAPIToken
    ) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.rightsService.initService(tenantId, token)
    }

    if (!this.root.campaignsService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.campaignsService.initService(tenantId, token)
    }
    this.loadCampaignsFromServer()
  }

  loadCampaignsFromServer(forceReload = false) {
    this.$store.commit('showProgressBar')
    this.campaigns = []
    console.log('[Test] loadCampaignsFromServer method called with forceReload = ', forceReload)
    if (forceReload || this.$store.state.campaigns.campaigns.length === 0) {
      this.root.campaignsService.getCampaigns$(forceReload || this.forceReloadFromDF).subscribe((response: any) => {
        console.log('[Test] loadCampaignsFromServer response:', response)
        if (response) {
          this.campaigns = _.orderBy(response, ['title'])
        } else {
          this.campaigns = []
        }
        this.$store.commit('hideProgressBar')
      })
    } else {
      this.campaigns = _.orderBy(this.$store.state.campaigns.campaigns, ['title'])
      console.log('[Test] campaigns from local store:', this.campaigns)
      this.$store.commit('hideProgressBar')
    }
  }

  getTitlesForObject(obj: any): string {
    let joinedString = ''
    for (const key of Object.keys(obj)) {
      joinedString = joinedString.length === 0 ? `${obj[key].title}` : `${joinedString}, ${obj[key].title}`
    }

    return joinedString
  }

  getArrayForObject(obj: any): any[] {
    const ary = []
    for (const key of Object.keys(obj)) {
      if (!obj[key].conf?.hidden) {
        ary.push({ key, ...obj[key] })
      }
    }

    return _.orderBy(ary, ['title'])
  }

  editChoosenGroups(group: any) {
    console.log('[Test] Clicked on Group:', group)
    if (!this.choosenGroups.includes(group.key)) {
      this.choosenGroups.push(group.key)
    } else {
      this.choosenGroups.splice(this.choosenGroups.indexOf(group.key), 1)
    }
    console.log('[Test] choosenGroups:', this.choosenGroups)
    this.openSnackbar(group)
  }

  editChoosenColumns(group: any) {
    console.log('[Test] Clicked on column:', group)
    if (!this.choosenColumns.includes(group.key)) {
      this.choosenColumns.push(group.key)
    } else {
      this.choosenColumns.splice(this.choosenColumns.indexOf(group.key), 1)
    }
    console.log('[Test] choosenColumns:', this.choosenColumns)
    this.openSnackbar(group)
  }

  editChoosenFilters(group: any) {
    console.log('[Test] Clicked on column:', group)
    if (!this.choosenFilters.includes(group.key)) {
      this.choosenFilters.push(group.key)
    } else {
      this.choosenFilters.splice(this.choosenFilters.indexOf(group.key), 1)
    }
    console.log('[Test] choosenFilters:', this.choosenFilters)
    this.openSnackbar(group)
  }

  openSnackbar(obj: any) {
    // console.log('[Test] Clicked on Object:', obj)
    this.snackbarText = `${obj.title} angeklickt!`
    this.snackbar = true
  }
}
