






















import { Vue, Component } from 'vue-property-decorator'
import DashboardComponent from '@/components/dashboard/Dashboard.vue'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { ErrorSnackData } from '@/appInterfaces/applications.interfaces'
import { forkJoin } from 'rxjs'

@Component({
  components: { DashboardComponent }
})
export default class Dashboard extends Vue {
  private root!: VueGevekom

  private showErrorSnack = false
  private errorSnackData: ErrorSnackData = {
    status: 500,
    errorMessage: '',
    timeout: 6000
  }

  private errorSnack = false
  private errorSnackText = ''

  created() {
    console.log('[Applications] Created the Applications class')
    this.root = this.$root as VueGevekom

    if (
      !this.root.rightsService.isInited &&
      this.$store?.state?.auth?.tenantId &&
      this.$store.state?.auth?.tokenDictionary?.QM?.tokens?.qmAPIToken
    ) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.rightsService.initService(tenantId, token)
    }

    if (!this.root.userSettingsService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.userSettingsService.initService(tenantId, token)
    }
    this.loadApplicationsFromServerForInit()
  }

  loadApplicationsFromServerForInit() {
    this.$store.commit('showProgressBar')
    const getSubs = forkJoin({
      dataConfigs: this.root.applicationService.getDataConfigs$()
    })

    getSubs.subscribe(
      (response: { dataConfigs: any }) => {
        console.log('[Applications] received result of forkJoin requests for INIT:', response)
        if (response.dataConfigs) {
          this.$store.commit('setConfigs', response.dataConfigs.data?.configs)
        }
        this.$store.commit('hideProgressBar')
      },
      (err: any) => {
        console.error('[Applications] ERROR:', err)
        this.$store.commit('setIncomingApplications', [])
        this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
  }
}
