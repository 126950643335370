






import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Skeleton extends Vue {
  private title = 'Skeleton works'

  test() {
    console.log('[Skeleton] Test method called')
  }
}
