import Vue from 'vue'
import Vuex from 'vuex'

import getInitialState from './Store.initialStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState())
    },
    increment(state) {
      state.count++
    },
    showProgressBar(state) {
      state.app.showProgressBar = true
    },
    hideProgressBar(state) {
      state.app.showProgressBar = false
    },
    setTenantId(state, payload) {
      state.auth.tenantId = payload
    },
    setTokenDictionary(state, tokenDictionary) {
      state.auth.tokenDictionary = { ...tokenDictionary }
    },
    setUser(state, payload) {
      state.auth.user = { ...payload }
      if (state.auth.user) {
        state.auth.viewRights = state.auth.user.tokens.QM.viewRights

        state.auth.isAuthenticated = true

        if (payload.modulesDictionary) {
          state.auth.tokenDictionary = payload.modulesDictionary
          if (payload.tokens && Object.keys(payload.tokens).length > 0) {
            for (const key of Object.keys(payload.tokens)) {
              state.auth.tokenDictionary[key] = payload.tokens[key]
              if (!state.auth.tenantId && payload.tokens[key].tenantId) {
                state.auth.tenantId = payload.tokens[key].tenantId
              }
            }
          }
        } else {
          state.auth.tokenDictionary = {}
        }
      } else {
        state.auth.isAuthenticated = false
      }
    },
    setShowSidebar(state, payload) {
      state.app.showSidebar = payload || false
    },
    setLang(state, lang) {
      state.auth.lang = lang
    },
    setCampaigns(state, campaigns) {
      console.log('[STORE] set campaigns to:', campaigns)
      state.campaigns.campaigns = [...campaigns]
    },
    addCampaigns(state, campaigns) {
      state.campaigns.campaigns = [...state.campaigns.campaigns, campaigns]
    },
    setRoles(state, roles) {
      state.company.roles = [...roles]
    },
    setConfigs(state, configs) {
      state.company.configs = { ...configs }
    }
  },
  actions: {
    resetAppState({ commit }) {
      commit('resetState')
    }
  },
  modules: {}
})
