











































































































































































































































































import { VueGevekom } from '@/components/applications/Form.interfaces'
import { Vue, Component, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { Campaign, ColumnDef, Group, ReportResponseData } from '@/appInterfaces/campaigns.interfaces'

@Component
export default class QmSelections extends Vue {
  private root!: VueGevekom

  private campaigns: Campaign[] = []
  private snackbar = false
  private snackbarText = ''

  private reportDialog = false
  private choiceDialog = false
  private choiceDialogConfig: Record<string, any> | null = null

  private choosenGroups: string[] = []
  private choosenColumns: string[] = []
  private choosenFilters: string[] = []
  private choosenFilterPairs: { [key: string]: string } = {}

  private forceReloadFromDF = false
  private selectedCampaign: any = null
  private selectedCampaignObject: Campaign | null | undefined = null
  private loadedCampaignObject: any = null
  private parsedReportData: any = null

  @Watch('selectedCampaign')
  selectedCampaignChanged(newValue: string) {
    this.selectedCampaignObject = _.find(this.campaigns, { id: newValue })
    console.log('[QmSelections] value of selectedCampaign has changed to:', this.selectedCampaignObject)
    if (this.selectedCampaignObject) {
      this.$store.commit('showProgressBar')
      this.loadedCampaignObject = null
      this.root.campaignsService.getCampaign$(this.selectedCampaignObject.id, this.forceReloadFromDF).subscribe((response: Campaign) => {
        console.log('[QmSelections] received campaign detail data from AS5:', response)
        if (response) {
          this.loadedCampaignObject = response
          this.getReportForCampaign()
        }
        this.$store.commit('hideProgressBar')
      })
    }
  }

  created() {
    console.log('[QmSelections] Created the QmSelections class')

    this.root = this.$root as VueGevekom

    if (
      !this.root.rightsService.isInited &&
      this.$store?.state?.auth?.tenantId &&
      this.$store.state?.auth?.tokenDictionary?.QM?.tokens?.qmAPIToken
    ) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.rightsService.initService(tenantId, token)
    }

    if (!this.root.campaignsService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.campaignsService.initService(tenantId, token)
    }
    this.loadCampaignsFromServer()
  }

  loadCampaignsFromServer(forceReload = false) {
    this.$store.commit('showProgressBar')
    this.campaigns = []
    console.log('[QmSelections] loadCampaignsFromServer method called with forceReload = ', forceReload)
    if (forceReload || this.$store.state.campaigns.campaigns.length === 0) {
      this.root.campaignsService.getCampaigns$(forceReload || this.forceReloadFromDF).subscribe((response: any) => {
        console.log('[QmSelections] loadCampaignsFromServer response:', response)
        if (response) {
          this.campaigns = _.orderBy(response, ['title'])
        } else {
          this.campaigns = []
        }
        this.$store.commit('hideProgressBar')
      })
    } else {
      this.campaigns = _.orderBy(this.$store.state.campaigns.campaigns, ['title'])
      console.log('[QmSelections] campaigns from local store:', this.campaigns)
      this.$store.commit('hideProgressBar')
    }
  }

  getTitlesForObject(obj: any): string {
    let joinedString = ''
    for (const key of Object.keys(obj)) {
      joinedString = joinedString.length === 0 ? `${obj[key].title}` : `${joinedString}, ${obj[key].title}`
    }

    return joinedString
  }

  getArrayForObject(obj: any): any[] {
    const ary = []
    for (const key of Object.keys(obj)) {
      if (!obj[key].conf?.hidden) {
        ary.push({ key, ...obj[key] })
      }
    }

    return _.orderBy(ary, ['title'])
  }

  getReportForCampaign() {
    this.$store.commit('showProgressBar')
    console.log('[QmSelections] getReportForCampaign called')
    if (this.selectedCampaignObject?.id) {
      const payload = {
        groups: this.choosenGroups,
        columns: this.choosenColumns,
        filters: this.choosenFilterPairs
      }
      console.log('[QmSelections] getReportForCampaign payload:', payload)
      this.root.campaignsService
        .getReportForCampaign$(this.selectedCampaignObject.id, payload)
        .subscribe((response: ReportResponseData) => {
          console.log('[QmSelections] Received report:', response)
          // this.parsedReportData = this.root.campaignsService.parseReportResponseData(response)
          this.parsedReportData = this.parseReportData(response)
          console.log('[QmSelections] Parsed report:', this.parsedReportData)
          this.$store.commit('hideProgressBar')
          if (this.parsedReportData?.length > 0) {
            this.reportDialog = true
          }
        })
    }
  }

  editChoosenGroups(group: any) {
    console.log('[QmSelections] Clicked on Group:', group)
    if (!this.choosenGroups.includes(group.key)) {
      this.choosenGroups.push(group.key)
    } else {
      this.choosenGroups.splice(this.choosenGroups.indexOf(group.key), 1)
    }
    console.log('[QmSelections] choosenGroups:', this.choosenGroups)
    // this.openSnackbar(group)
  }

  editChoosenColumns(group: any) {
    console.log('[QmSelections] Clicked on column:', group)
    if (!this.choosenColumns.includes(group.key)) {
      this.choosenColumns.push(group.key)
    } else {
      this.choosenColumns.splice(this.choosenColumns.indexOf(group.key), 1)
    }
    console.log('[QmSelections] choosenColumns:', this.choosenColumns)
    // this.openSnackbar(group)
  }

  editChoosenFilters(group: any) {
    console.log('[QmSelections] Clicked on column:', group)
    if (!this.choosenFilters.includes(group.key)) {
      this.choiceDialogConfig = {
        group,
        choices: group.conf.choices,
        value: null,
        result: null
      }

      this.choiceDialog = true
    } else {
      this.choosenFilters.splice(this.choosenFilters.indexOf(group.key), 1)
      delete this.choosenFilterPairs[group.key]
      console.log('[QmSelections] choosenFilters:', this.choosenFilters)
      console.log('[QmSelections] choosenFilterPairs:', this.choosenFilterPairs)
    }
  }

  processChoiceDialogResult(result: any) {
    console.log('[QmSelections] processChoiceDialogResult:', result)
    if (result.result === 'cancel') {
      this.choiceDialog = false
    }
    if (result.result === 'ok' && result.value) {
      if (!this.choosenFilters.includes(result.group.key)) {
        this.choosenFilterPairs[result.group.key] = result.value
        this.choosenFilters.push(result.group.key)
      }
    }
    console.log('[QmSelections] choosenFilters:', this.choosenFilters)
    console.log('[QmSelections] choosenFilterPairs:', this.choosenFilterPairs)

    this.choiceDialog = false
  }

  openSnackbar(obj: any) {
    // console.log('[QmSelections] Clicked on Object:', obj)
    this.snackbarText = `${obj.title} angeklickt!`
    this.snackbar = true
  }

  parseReportData(data: ReportResponseData) {
    const columnsIdxs: { [colName: string]: number } = {}
    const fieldsIdx: { [colName: string]: ColumnDef } = {}

    const report: any[] = []

    let i = 0
    for (const colDef of data.columnDefs) {
      columnsIdxs[colDef.name] = i++
    }

    // console.log('[QmSelections -> parseReportData] index of columns:', columnsIdxs)

    for (const colDef of data.columnDefs) {
      fieldsIdx[colDef.name] = colDef
    }

    // console.log('[QmSelections -> parseReportData] map of columns:', fieldsIdx)

    if (data.groups?.length > 0) {
      const cols: string[] = []
      for (const key of Object.keys(fieldsIdx)) {
        cols.push(fieldsIdx[key].title)
      }
      report.push(['Gruppen-Level', 'Gruppen-Titel', ...cols])

      i = 0
      for (const group of data.groups) {
        this.addGroupToReport(0, report, group)
      }
    }

    console.log('[QmSelections -> parseReportData] Result for report:', report)
    return report
  }

  private addGroupToReport(groupLevel: number, report: any[], group: Group) {
    report.push([groupLevel, group.value, ...group.columns])
    if (group.groups && group.groups.length > 0) {
      for (const innerGroup of group.groups) {
        this.addGroupToReport(groupLevel + 1, report, innerGroup)
      }
    }
  }

  private getParsedReportDataRows(report: any[]) {
    const dataRows = [...report]
    dataRows.splice(0, 1)
    return dataRows
  }

  private getFilterTitle(filter: any, filterKey: string) {
    // console.log('[QmSelections] lookup filter name with key')
    // return filterKey ? this.loadedCampaignObject.statisticFields.filters[filterKey].title : ''
    const choice = _.find(filter.conf.choices, { value: filterKey })
    return choice ? choice.title : filterKey
  }
}
