import { BehaviorSubject, defer, Observable } from 'rxjs'
import http from '../http-common'

import store from '@/store'
import { map } from 'rxjs/operators'
import { AxiosResponse } from 'axios'
import { Campaign, ReportResponseData } from '@/appInterfaces/campaigns.interfaces'

class CampaignsSingletonService {
  private _isInited = false
  private headersBasic: Record<string, any> = {}

  public tenantId!: string
  public token!: string

  private _campaigns$ = new BehaviorSubject<any[]>([])
  public campaigns$ = this._campaigns$.asObservable()

  get isInited(): boolean {
    return this._isInited
  }

  initService(tenantId: string, token: string, overwrite = false) {
    if (!this._isInited || overwrite) {
      this.tenantId = tenantId
      this.token = token
      this.headersBasic = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
      this._isInited = true
      console.log('[CampaignsSingletonService] has been inited!')
      console.log('[CampaignsSingletonService] Found store:', store.state)
    }
  }

  getTest$(): Observable<any> {
    return defer(() => http.get(`/api/campaigns/v1/tenants/${this.tenantId}/test`, this.headersBasic)).pipe(
      map((response: AxiosResponse) => {
        return response
      })
    )
  }

  getCampaigns$(forceReload = false): Observable<Campaign> {
    const config = { ...this.headersBasic }
    if (forceReload) {
      config.params = {
        forceDialFireReload: 'true'
      }
    }

    return defer(() => http.get(`/api/campaigns/v1/tenants/${this.tenantId}/`, config)).pipe(
      map((response: AxiosResponse) => {
        console.log('[CampaignsSingletonService] received campaigns:', response.data.campaigns)
        if (response?.data?.campaigns) {
          this._campaigns$.next(response.data?.campaigns)
          store.commit('setCampaigns', response.data?.campaigns)
        } else {
          this._campaigns$.next([])
        }
        return response?.data?.campaigns || []
      })
    )
  }

  getCampaign$(id: string, forceReload = false): Observable<any> {
    const config = { ...this.headersBasic }
    if (forceReload) {
      config.params = {
        forceDialFireReload: 'true'
      }
    }

    return defer(() => http.get(`/api/campaigns/v1/tenants/${this.tenantId}/${id}`, config)).pipe(
      map((response: AxiosResponse) => {
        console.log('[CampaignsSingletonService] received campaign:', response.data.campaign)
        return response?.data?.campaign || null
      })
    )
  }

  getReportForCampaign$(id: string, payload: Record<string, any>): Observable<ReportResponseData> {
    const config = { ...this.headersBasic }

    return defer(() => http.post(`/api/campaigns/v1/tenants/${this.tenantId}/${id}/report`, payload, config)).pipe(
      map((response: AxiosResponse) => {
        console.log('[CampaignsSingletonService] received report result:', response.data.report)
        return response?.data?.report || null
      })
    )
  }

  parseReportResponseData(data: any): any {
    const total: any = {}
    const lines: any = {}

    const indexes: any = {}

    // sammeln der Indexe, um komfortabel drauf zugreifen zu können
    for (let i = 0; i < data.columnDefs.length; i++) {
      indexes[data.columnDefs[i].name] = i
    }

    const fieldDefs: any = {}
    if (data.columnDefs) {
      data.columnDefs.forEach((colDef: any) => (fieldDefs[colDef.name] = colDef))
    }

    if (data.groups && data.groups.length === 1) {
      const entry: any = data.groups[0]
      const totalTmp: any = {}
      for (const key in indexes) {
        totalTmp[key] = entry.columns[indexes[key]]
      }
      // lines['total'] = totalTmp
      // lines['fieldDefs'] = fieldDefs
      lines.total = totalTmp
      lines.fieldDefs = fieldDefs

      let slot
      if (entry.groups) {
        for (let i = 0; i < entry.groups.length; i++) {
          const group: any = entry.groups[i]
          const tentry: any = entry.groups[i].columns

          let line: any = lines[group.value]

          for (const key in indexes) {
            total[key] = tentry[indexes[key]]
          }

          // erzeugen der line, falls erforderlich
          if (!line) {
            line = {}
            lines[group.value] = line
          }

          if (group.groups) {
            if (!entry.groups) throw new Error('raus 3')

            for (let j = 0; j < group.groups.length; j++) {
              const timeEntry: any = group.groups[j]
              if (timeEntry) {
                slot = line[timeEntry.value]
                if (!slot) {
                  slot = {}
                  line[timeEntry.value] = slot
                }

                for (const key in indexes) {
                  slot[key] = timeEntry.columns[indexes[key]]
                }
              }
            }
          }

          if (!group.columns) throw new Error('raus 4')
          if (group.columns && group.columns.length > 0) {
            if (!line.total) line.total = {}
            for (const key in indexes) {
              line.total[key] = group.columns[indexes[key]]
            }
          }
        }
      }
    }
    return lines
  }
}

export default CampaignsSingletonService
