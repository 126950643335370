





















































































import { Vue, Component } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'

import _ from 'lodash'
import { ConfigKeys } from './settings.interfaces'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

@Component({ components: { ConfirmDialog } })
export default class DropdownContentsSettings extends Vue {
  private root!: VueGevekom
  private errorSnack = false
  private errorSnackText = ''

  private configKeys: ConfigKeys[] = []
  private selectedConfig: string | null = null
  private selectedItem = null

  private listBox = null
  private editDialog = false
  private editDropdownDisplayText = ''
  private editItem: any
  private deleteItem: any
  private deleteIndex!: number

  private showConfirmDialog = false

  created() {
    console.log('[DropdownContentsSettings] has been created!')
    this.root = this.$root as VueGevekom
    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.QM.tokens.qmAPIToken

      this.root.applicationService.initService(tenantId, token)
    }

    const keyNames = _.filter(Object.keys(this.$store.state.company.configs), (key) => key !== 'configDisplayNames')
    const keys: ConfigKeys[] = []
    for (const key of keyNames) {
      keys.push({ displayName: this.$store.state.company.configs.configDisplayNames[key], value: key })
    }
    this.configKeys = _.orderBy(keys, ['displayName'])
    console.log('[DropdownContentsSettings] found configs:', this.configKeys)
  }

  addNewConfigEntry() {
    console.log('[DropdownContentsSettings] add list item')
    this.editItem = { displayText: '', value: null }
    this.editDropdownDisplayText = this.editItem.displayText
    this.editDialog = true
  }

  editListItem(idx?: number) {
    if (this.selectedConfig) {
      if (idx) {
        this.editItem = this.$store.state.company.configs[this.selectedConfig][idx]
      } else {
        console.log('[DropdownContentsSettings] selected item:', this.selectedItem)
        this.editItem = this.$store.state.company.configs[this.selectedConfig][this.selectedItem || 0]
      }
      if (this.editItem) {
        console.log('[DropdownContentsSettings] selected item:', this.editItem.displayText)
        //   editItem.displayText += '__1'
        this.editDropdownDisplayText = this.editItem.displayText
        this.editDialog = true
      }
    }
    this.$nextTick(() => {
      this.selectedItem = null
    })
  }

  deleteListItem(idx: number) {
    console.log('[DropdownContentsSettings] Delete selected index:', idx)
    if (this.selectedConfig) {
      this.deleteIndex = idx
      this.deleteItem = this.$store.state.company.configs[this.selectedConfig][this.deleteIndex]
      console.log('[DropdownContentsSettings] Delete selected item:', this.deleteItem)
      this.showConfirmDialog = true
    }
    this.$nextTick(() => {
      this.selectedItem = null
    })
  }

  deleteConfirmed() {
    console.log('[DropdownContentsSettings] Delete confirmed')
    if (this.selectedConfig) {
      const newConfig = this.$store.state.company.configs[this.selectedConfig]
      newConfig.splice(this.deleteIndex, 1)
      this.$store.state.company.configs[this.selectedConfig] = newConfig
      this.updateConfigOnServer(this.selectedConfig)
    }
    this.showConfirmDialog = false
  }

  cancelEditDialog() {
    console.log('[DropdownContentsSettings] Cancel edit')
    this.editDialog = false
  }

  saveEditDialog() {
    console.log('[DropdownContentsSettings] Save edit:', this.editItem)
    console.log('[DropdownContentsSettings] Save edit with CamelCase value:', _.camelCase(this.editDropdownDisplayText))
    if (this.selectedConfig) {
      this.editItem.displayText = this.editDropdownDisplayText
      const newList = this.$store.state.company.configs[this.selectedConfig]
      if (!this.editItem.value || this.editItem.value === '') {
        this.editItem.value = _.camelCase(this.editDropdownDisplayText)
        newList.push(this.editItem)
      }
      this.$store.state.company.configs[this.selectedConfig] = _.orderBy(newList, ['displayText'])
      this.editDialog = false
      console.log(
        '[DropdownContentsSettings] Save this config %o for this key:',
        this.selectedConfig,
        this.$store.state.company.configs[this.selectedConfig]
      )
      this.updateConfigOnServer(this.selectedConfig)
    }
  }

  private updateConfigOnServer(selectedConfig: string) {
    this.root.applicationService.updateDataConfig$(selectedConfig, this.$store.state.company.configs[selectedConfig]).subscribe(
      (response: any) => {
        console.log('[DropdownContentsSettings] Response from save:', response)
      },
      (error: any) => {
        console.log('[DropdownContentsSettings] Error from save:', error)
        this.errorSnackText = `${error}`
        this.errorSnack = true
        switch (error.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
  }
}
